import React from 'react'; 
import './aboutme.css';
import ProfileImage from '../../assets/a1.jpg'; 

const AboutMe = () => {
  // Funzione per tornare alla homepage
  const handleHomeClick = () => {
    window.location.href = "/"; // Modifica l'URL con la tua homepage
  };

  // Funzione per aprire il video su YouTube
  const handleVideoClick = () => {
    window.open("https://www.youtube.com/watch?v=wGRF3GQ4Wdk", "_blank");
  };

  return (
    <div className='profile'>
      <img src={ProfileImage} alt="Profile" className="profile-image" />
      <div className="buttons">
        <div className="text-container">
          <h3 className="intro">I'm</h3>
          <h1 className="name">Giosue Aiello</h1>
          <h2 className="role">Inventore</h2>
        </div>
        <div className="btn-container">
          <button className="button" onClick={handleHomeClick}>Download CV</button>
          <button className="play-btn" onClick={handleVideoClick}></button>
          <h5 className="text1">Play Video</h5>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
