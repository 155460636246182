import React from 'react'; 
import './FooterStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>Tanz</h4>
            <ul>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">about us</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">our goals</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">our services</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">current projects</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Profile</h4>
            <ul>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">Works</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">about me</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">Education</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">curriculum</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>online shop</h4>
            <ul>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">bag</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">band</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">shoes</a></li>
              <li><a href="#" onClick={(e) => e.preventDefault()} role="button">T-shirts</a></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/GiosueAiello.tanz">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://x.com/giosue_aiello">
                <FontAwesomeIcon icon={faXTwitter} /> {/* Icona aggiornata */}
              </a>
              <a href="https://www.instagram.com/giosue.aie/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com/in/giosu%C3%A8-aiello-425474243/">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
